// Comfortaa light
@font-face {
    font-family: Montserrat;
    src: url(../../../assets/fonts/Montserrat/Montserrat-Medium.ttf);
    font-style: normal;
}


@font-face {
    font-family: Montserrat-Bold;
    src: url(../../../assets/fonts/Montserrat/Montserrat-Bold.ttf);
    font-style: normal;
}
@font-face {
    font-family: Montserrat-Black;
    src: url(../../../assets/fonts/Montserrat/Montserrat-Black.ttf);
    font-style: normal;
}
@font-face {
    font-family: Avroche;
    src: url(../../../assets/fonts/AVROCHE.otf);
    font-style: normal;
}
@font-face {
    font-family: Walkiwar;
    src: url(../../../assets/fonts/Walkiwar.otf);
    font-style: normal;
}