// Animation css
@import '~animate.css/animate.min.css';

// Bootstrap Layout scss
@import 'node_modules/bootstrap/scss/bootstrap';

// Owl Carousel
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Themify icon scss
@import 'themify';

// Theme Scss
@import 'theme/variables';

:root {
    --theme-deafult: #fed150;
    // This gradient color only for gym and marijuana layout
    --theme-gradient1: #ffffff;
    --theme-gradient2: #535251;
}

@font-face {
    font-family: 'sunday';
    src: url('../fonts/sunday-regular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'signika-regular';
    src: url('../fonts/signika-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'signika-light';
    src: url('../fonts/signika-light.ttf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'signika-semibold';
    src: url('../fonts/signika-semibold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'signika';
    src: url('../fonts/signika-bold.ttf') format('truetype');
    font-weight: bolder;
    font-style: normal;
}

@import 'theme/style';
@import 'theme/theme-dark';
@import 'theme/menu';
@import 'theme/responsive';
@import "theme/fonts";

// Toastr Css
@import '../../../node_modules/ngx-toastr/toastr.css';

// Rating Scss
@import 'node_modules/ngx-bar-rating/themes/br-default-theme';

@import 'node_modules/@fortawesome/fontawesome-free/css/brands.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/regular.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/solid.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';

.bg-light-grey {
    background-color: $eit-bg-grey;
}

/*
.header-gym .main-menu .menu-left .navbar, .header-internal .main-menu .menu-left .navbar {
    padding: 20px 45px 0px 10px;
}
*/
.navbar-dark .navbar-nav .nav-link {
    color: $white;
    font-weight: 700;
    font-family: 'Open Sans';
    font-size: 13px;
    padding-bottom: 12px;
}

header.header-gym .main-navbar .nav-menu>li>a,
header.header-internal .main-navbar .nav-menu>li>a {
    color: $eit-bg-grey;
    padding-top: 20px;
    padding-bottom: 18px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Open Sans';
}

/*
.header-gym .onhover-div, .header-internal .onhover-div {
    padding-top: 0px;
    padding-bottom: 0px;
}
*/
header.header-gym .main-navbar .nav-menu>li>a,
header.header-internal .main-navbar .nav-menu>li>a {
    color: $white;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 20px;
    padding-right: 20px !important;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Walkiwar';
}

.main-menu .menu-right .icon-nav .mobile-cart>div img {
    display: inline !important;
}

.main-menu .menu-right .icon-nav .mobile-cart {
    display: inline-block !important;
}

header .main-navbar .nav-menu>li.onhover-div>a {
    padding-right: 20px !important;
}

.header-gym .onhover-div>div img,
.header-internal .onhover-div>div img {
    max-height: 60px !important;
}

.page-header {
    /* background-image: url("/assets/images/bg-header-small.png");*/
    background-repeat: no-repeat;
    background-size: 110% 100%;
    background-position: 100% 0;
    padding-top: 200px;
    padding-bottom: 60px;
    overflow-y: visible;

    h1 {
        text-align: center;
        text-transform: none;
        font-size: 37px !important;
        font-weight: 400;
    }

    h2 {
        text-align: center;
        text-transform: none;
        font-size: 29px !important;
        font-weight: 400;
    }
}

.margin-header {
    margin-top: -79px;
}

.margin-header-big {
    margin-top: -300px;
}

.box {
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
}

.box-title {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    background-color: $eit-bg-grey !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h3 {
        color: #ffffff !important;
    }
}

.eit-bt-border {
    border-bottom: 1px solid #9e9e9e !important;

    img {
        max-width: 50px !important;
    }
}

.btn-eit {
    width: 100%;
    max-width: 300px;
    background-color: $eit-yellow;
    border-radius: 12px;
    min-height: 35px;
    height: auto;
    color: $white;
    font-size: 18px;
    text-transform: none;
}

.btn-eit-yellow {
    background-color: $eit-yellow;
    color: $white;
    border-radius: 12px;
    border: 2px solid $eit-yellow;
    background-image: none !important;
}

.product-title {
    font-family: 'Open Sans';
    color: $eit-bg-grey;
    font-weight: normal;
}

.media-body p {
    font-family: signika-light;
    color: $white;
}

.btn-facebook {
    background-color: #0247c1 !important;
    background-image: none !important;
    border: none !important;
}

.login-header {
    background-image: url("/assets/images/bg-header.png");
    background-repeat: no-repeat;
    background-size: 110% 100%;
    background-position: 100% 0;
    padding-bottom: 100px !important;
    padding-top: 110px !important;
    margin-bottom: 10px;
    overflow-y: visible;

    h1 {
        text-align: center;
        color: $white;
        text-transform: uppercase;
    }
}

.login-card {
    /*-webkit-box-shadow: 1px -1px 28px -4px rgba(0,0,0,0.65);
    -moz-box-shadow: 1px -1px 28px -4px rgba(0,0,0,0.65);
    box-shadow: 1px -1px 28px -4px rgba(0,0,0,0.65);*/
    border-radius: 0;
    border: none;
    padding: 8px;
    background-color: $white !important;

    .card-body {
        padding: 2.5rem 0px;
        // background: $eit-bg-grey;
    }

    .card-header {
        background: $eit-bg-grey;
        height: 75px;
        border-bottom: 0;
    }

    p {
        color: $eit-yellow !important;
        font-family: "Walkiwar";
        font-size: 20px;

    }
}

.login-form,
.modal-body {

    form>input,
    form>textarea {
        max-width: 300px;
        margin: auto;
        background: #f9f9f7;
        border-radius: 12px;
        height: 35px;
    }

    form>input:focus,
    form>textarea:focus {
        border: 1px solid $eit-yellow;
        box-shadow: none;
    }

    textarea {
        height: 105px;
        resize: none;
    }

    select {
        max-width: 300px;
        width: 100%;
        margin: auto;
        background: #f9f9f7;
        border-radius: 12px;
        height: 35px;
        border: 1px solid #ced4da;
    }
}

a {
    cursor: pointer;
}

.errPar {
    color: $eit-red !important;
}

@media (max-width: 577px) {
    footer {
        margin-bottom: 0 !important;
    }
}

@media only screen and (max-width: 768px) {
    .scarica-app {
        text-align: center;
    }
}

@media only screen and (max-width: 950px) {
    .big-img {
        margin-top: -121px !important;
        background-size: 100% 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 28px !important;
    }

    .chi-siamo-section {
        background-color: whitesmoke;
    }

    .big-img {
        height: 360px !important;
    }
}

.dashboard-left {
    li:before {
        content: none !important;
    }

    li {
        padding: 5px;
        height: 42px;
    }

    a {
        font-size: 20px;
        display: flex;
        align-items: flex-end;
        font-family: 'Open Sans';
        line-height: 0.8;
    }

    li.active a {
        color: $eit-yellow !important;
    }

    li:hover,
    li:hover a,
    a:hover {
        color: $eit-yellow !important;
    }

    img {
        width: 30px;
        margin-right: 15px;
    }

    li:last-child {
        img {
            width: 25px;
            margin-left: 5px;
        }
    }

    .block-content {
        border-color: $eit-yellow;
        border-radius: 8px;
    }
}

.dashboard-right {
    .dashboard {
        border-width: 0;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $eit-yellow !important;
            font-family: Walkiwar;
        }

        h2 {
            font-size: 25px !important;
        }

        h3 {
            font-size: 24px;
            margin-top: 20px;
        }

        .page-title h2 {
            color: $eit-bg-grey !important;
            font-weight: 400;
            text-transform: none;

            .btn-eit {
                max-width: 100%;
                width: auto;
                font-size: 14px;
            }
        }

        .form-control {
            border-width: 0 0 3px 0;
        }

        .form-control:focus {
            box-shadow: none;
            border-width: 0 0 3px 0 !important;
            border-color: $eit-yellow !important;
            background-color: transparent !important;
        }

        .btn-eit {
            margin-top: 20px;
            max-width: 120px;
            text-transform: uppercase;
            padding: 2px 12px !important;
            border-radius: 8px;
            min-height: 30px;
        }

        .err {
            color: $eit-red !important;
            text-align: end;
            padding: 10px 0 0 0;
        }

        .errInput {
            border-color: $eit-yellow !important;
        }
    }
}

.account-sidebar {
    background-color: $eit-yellow;
}

.blog-detail-page .comment-section {

    padding-top: 20px;

    li {
        width: 100%;
        border-top-width: 2px;
        padding-bottom: 20px;
        padding-top: 20px;

        .br-unit::after {
            content: none;
        }

        .br-unit.br-selected {
            background-image: url("/assets/images/stella-recensioni.png");
            height: 16px !important;
            width: 17px !important;
            background-size: 100% 100%;
        }

        .br {
            margin: 3px 0;
        }

        p {
            line-height: 1.2;
            margin-bottom: 0.2rem;
            font-size: 13px;
            letter-spacing: 0em;
        }

        h4 {
            font-size: 16px;
        }
    }
}

.rotate180 {
    transform: rotate(180deg);
}

.c-pointer {
    cursor: pointer;
}

.tap-top {
    background: $eit-yellow;
}

.c-checkbox {
    font-size: 1rem;
}

.c-checkbox input[type=checkbox]:checked+span:before {
    color: #fff;
    opacity: 1;
    -webkit-transition: color .3s ease-out;
    transition: color .3s ease-out;
}

.c-checkbox span:before,
.c-radio span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
}

.c-checkbox span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
    text-align: center;
}

.c-checkbox input {
    opacity: 0;
}

.c-checkbox input[type=checkbox]:checked+span {
    background: $eit-yellow;
    border-color: $eit-yellow;
}

.corporate-box {

    .col-lg-3 {
        padding: 15px;
    }

    .img-container {

        height: 150px;
        text-align: center;
        border: 2px solid #dddddd;
        border-radius: 8px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            max-height: 145px;
            margin: auto;
            max-width: 100%;
        }

        span {
            position: absolute;
            top: -2px;
            right: -2px;
            display: block;
            width: 25px;
            height: 25px;
            border-radius: 0 8px;
            background: $eit-yellow;
            font-size: 19px;
            line-height: 1.3;
        }
    }
}

.modal-body {
    h2 {
        color: $eit-yellow;
        font-size: 25px;
    }

    h4 {
        color: $black;
        text-transform: none;
    }

    .btn-eit {
        width: auto;
        padding: 6px 29px;
    }

    .bt-eit {
        border-top: 2px solid $eit-yellow;
    }
}

.eit-card {

    -webkit-box-shadow: 1px -1px 28px -4px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 1px -1px 28px -4px rgba(0, 0, 0, 0.65);
    box-shadow: 1px -1px 28px -4px rgba(0, 0, 0, 0.65);
    border-radius: 24px;
    border: 0;

    .card-body {
        padding: 2.5rem;
    }

    .card-header {
        border-radius: 24px 24px 0 0;
        background: $eit-yellow;
        text-align: center;
        color: #fff;
        line-height: 29px;
        font-size: 22px;
        min-height: 50px;
        font-family: signika;
    }

    p {
        color: $eit-yellow;
    }

    h3 {
        font-size: 16px;
        font-family: signika;
    }

    .c-radio {
        font-size: 1rem;
        display: inline-flex;
        width: 50px;
        height: 35px;
        margin-right: 15px;
        position: relative;
        align-items: center;

        label {
            display: inline-block;
            margin-bottom: 0.5rem;
            margin: auto;
            z-index: 0;
            font-size: 1rem;
            width: 50px;
            background: #f9f9f7;
            border-radius: 12px;
            height: 35px;
            border: 1px solid #ced4da;
            align-items: center;
            text-align: center;
            line-height: 31px;
        }

        input[type=radio] {
            opacity: 0;
            position: absolute;
            z-index: 1;
            width: 50px;
            cursor: pointer;
        }

        input[type=radio]:checked+label {
            color: #fff;
            opacity: 1;
            -webkit-transition: color .3s ease-out;
            transition: color .3s ease-out;
            background: $eit-yellow;
            border-color: $eit-yellow;
        }
    }

}

.footer-section {
    position: relative;
    padding-top: 0;
    background-image: url(/assets/images/bg-footer-right.png);
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    padding-top: 50px;
    background-size: 100% 100%;

    /*  .layer{
        background-image: url(/assets/images/bg-left.png);
        transform: rotate(180deg);
        width: 100%;
        height: 800px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }*/
    img {
        width: 77%;
        max-width: 700px;
    }
}

.theme-tab .tab-title .nav-link.active,
.theme-tab .tab-title2 .nav-link.active {
    color: $eit-yellow !important;
}


.chi-siamo-section {
    background-image: url(/assets/images/layer-grey.png);
    background-size: 100% 100%;

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $eit-grey;
    }

    p {
        font-size: 18px;
        line-height: 1.4;
        color: $eit-grey;
    }

    img {
        max-height: 360px;
        max-width: 100%;
    }
}

.affiliazione-section {
    .section-header {
        background-image: url(/assets/images/bg-header.png);
        background-size: 100% 100%;
        height: 300px;

        h1 {
            color: $eit-red;
            font-size: 50px;
            text-align: center;
        }
    }

    p,
    a {
        font-size: 18px;
        line-height: 1.9;
        color: $eit-grey;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $eit-grey;
    }

    h4 {
        font-size: 22px;
    }

    ul {
        li {
            display: block;
            position: relative;
            padding-left: 20px;
            font-size: 18px;
            line-height: 1.8;
            color: $eit-grey;

            i {
                font-size: 5px;
                top: 14px;
                left: 0;
                position: absolute;
            }
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.ufficio-section {
    margin-top: -50px;

    img {
        width: 100%;
    }
}

.big-img {
    margin-top: -120px;
    height: 120vh;
    background-image: url(/assets/images/42324071_l.jpg);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.rounded-image {
    border-radius: 12px;
}

.corporates-section {
    h2 {
        color: $eit-yellow;
    }

    img {
        max-width: 100%;
        max-height: 250px;
    }
}

.dove-siamo-section {
    background-color: $eit-yellow;

    img {
        max-width: 100%;
    }
}

input:focus {
    box-shadow: none !important;
}

.collection-brand-filter::-webkit-scrollbar-thumb {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.62, $eit-yellow), color-stop(0.86, $eit-yellow)) !important;
}

.rest-calendar {
    .fc-title {
        font-size: 1.2em;
    }

    .fc-toolbar-title {
        width: 100%;
        color: #fdca05 !important;
    }

    .fc-toolbar h2 {
        text-transform: capitalize;
        font-size: 1.5em;
    }

    .fc-today-button {
        display: none !important;
    }

    .fc-view-harness {
        height: 380px !important;
    }

    .fc-highlight {
        //background: #fdca05 !important;
        background: rgba(253, 202, 5, 0.8) !important;
        background: var(--fc-highlight-color, rgba(253, 202, 5, 0.8));
    }

    .fc-button-primary {
        background: rgba(253, 202, 5, 0.7) !important;
        background: var(--fc-highlight-color, rgba(253, 202, 5, 0.8));
        border-color: #fdca05 !important;
    }

    .fc-daygrid {
        //border: none !important;
        //color: black !important;

        table {
            tbody {
                tr {
                    border: 3px #fdca05 dashed !important;

                    a {
                        color: black !important;
                    }

                    a:hover {
                        color: #ffcb04 !important;
                    }
                }
            }
        }
    }

}

.bordered-container {
    border: 1px solid $eit-yellow;
}

.mobile-footer-fixed a.btn {
    // margin-top: 10px;
    color: $eit-yellow !important;
    // font-size:18px;
    width: 90vw;
}

.btn-solid.btn-block {
    background-size: 2500px;
}

.p-title {
    font-size: 18px;
    color: $white;
}

.divider {
    background-image: linear-gradient(to right, $eit-yellow 40%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 10px 2px;
    background-repeat: repeat-x;
    height: 2px;
    margin: 40px 0;
}

.divider.no-margin {
    margin: 0;
}

.pac-container {
    z-index: 10000;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $eit-yellow;
    background-color: $eit-yellow;
}

.custom-control-input:focus {
    box-shadow: $eit-yellow;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(253, 202, 5, 0.25) !important;
}

.divider-mark {
    width: 80px;
    height: 4px;
    margin: 30px auto;
    background-color: transparent !important;
}

.cc-window {
    padding: 16px 0 !important;
    color: #fff !important;
    font-size: 14px !important;
    z-index: 10001 !important;

    .cc-compliance {
        margin-right: 1em;
    }

    .cc-link {
        color: $white !important;
    }

    .cc-btn {
        padding: 4px 16px !important;
        height: 32px !important;
        font-size: 14px;
        font-weight: 400;

        &.cc-deny {
            color: #fff;
            background: #000;
            border-width: 1px;
            border-color: #fff;
            margin-right: 1em;
        }
    }
}

.big-cart-box,
.checkout-page {
    padding: 8px;
    border: 2px solid var(--theme-deafult);
}

.cashpay-page,
.cardpay-page {
    padding-top: 180px !important;
}

.waitingImg {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

a {
    color: $eit-yellow;
    font-weight: bold;

    &:hover {
        color: $eit-yellow;
    }
}

.close {
    color: $black !important;
    border: none !important;
    background: none !important;
}

.modal-header {
    border-bottom: 1px solid var(--theme-deafult) !important;
}

.modal-footer {
    border-top: 1px solid var(--theme-deafult) !important;
}

.modal-body {
    h4 {
        color: $eit-bg-grey !important;
    }
}

p {
    color: $eit-bg-grey !important;
}

.backBtn {
    display: none !important;
}

@media(max-width: 767px) {

    .backBtn {
        display: block !important;
    }
}

.custom-control-label {
    color: $eit-bg-grey !important;
}

.togglePassword {
    margin-left: -30px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 18px;
    color: $eit-yellow;
}

.toast-container {
    position: fixed !important;
}

.eclaim {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    width: auto;

    tspan {
        font-size: 25px !important;
        font-family: "Avroche", sans-serif;
        font-family: "Avroche", sans-serif;
        fill: var(--cs-text-primary, #2ABDCE) !important;
        color: var(--cs-text-primary, #2ABDCE) !important;
    }
}